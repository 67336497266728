// @flow
import React from 'react'
import {Box, Flex, Image} from 'rebass'

import createAlignmentPlugin from 'draft-js-alignment-plugin'
import createFocusPlugin from 'draft-js-focus-plugin'
import createImagePlugin from 'draft-js-image-plugin'
import createLinkifyPlugin from 'draft-js-linkify-plugin'
import createMentionPlugin from 'draft-js-mention-plugin'
import {composeDecorators} from 'draft-js-plugins-editor'
import createResizeablePlugin from 'gustavochavarria-draft-js-resizeable-plugin'

import createEmojiPlugin from '../../plugins/emoji'
import createTextLinkPlugin from '../../plugin/linkPlugin'
import './mentionStyle.css'

const Entry = (props) => {
  const {
    mention,
    theme,
    searchValue, // eslint-disable-line no-unused-vars
    isFocused, // eslint-disable-line no-unused-vars
    ...parentProps
  } = props
  return (
    <Flex {...parentProps}>
      <Flex p={10} style={{cursor: `pointer`}}>
        {mention.avatarUrl && (
          <Flex mr={`8px`}>
            <Image
              height={20}
              role="presentation"
              src={mention.avatarUrl}
              style={{borderRadius: `50%`}}
              width={20}
            />
          </Flex>
        )}

        <Flex flex={1}>
          <Box>{mention.name}</Box>
        </Flex>
      </Flex>
    </Flex>
  )
}

class HelperPlugins {
  constructor(props) {
    this.isChatComposer = props.isChatComposer
    this.showToolbar = props.showToolbar

    this.mentionPlugin = createMentionPlugin({
      theme: {
        mention: `mentionSuggestionSelected`,
        mentionSuggestions: `mentionSuggestions`,
        mentionSuggestionsEntryFocused: `mentionSuggestionsEntryFocused`,
      },
    })

    this.emojiPlugin = createEmojiPlugin()
    this.linkifyPlugin = createLinkifyPlugin({
      rel: `noopener noreferrer nofollow`,
      target: `_blank`,
    })

    if (this.showToolbar) {
      this.alignmentPlugin = createAlignmentPlugin()
      this.focusPlugin = createFocusPlugin()
      this.resizeablePlugin = createResizeablePlugin()

      const imageDecorator = composeDecorators(
        this.resizeablePlugin.decorator,
        this.alignmentPlugin.decorator,
        this.focusPlugin.decorator
      )

      this.imagePlugin = createImagePlugin({
        decorator: imageDecorator,
      })

      this.textLinkPlugin = createTextLinkPlugin()
    }
  }

  getPluginsComponents() {
    if (!this.showToolbar) {
      return {}
    }

    const {AlignmentTool} = this.alignmentPlugin

    return {AlignmentTool}
  }

  getPluginMention() {
    const {MentionSuggestions} = this.mentionPlugin

    return {MentionSuggestions, Entry}
  }

  getPlugins() {
    const plugins = [this.emojiPlugin, this.linkifyPlugin, this.mentionPlugin]

    if (this.showToolbar) {
      plugins.push(
        this.alignmentPlugin,
        this.resizeablePlugin,
        this.imagePlugin,
        this.textLinkPlugin
      )

      if (!this.isChatComposer) {
        plugins.push(this.focusPlugin)
      }
    }

    return plugins
  }
}

export default HelperPlugins
