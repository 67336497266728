import styled, {keyframes} from 'styled-components'

const spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`

export const Spinner = styled.span`
  height: ${(props) => (props.size ? `${props.size}px` : `32px`)};
  width: ${(props) => (props.size ? `${props.size}px` : `32px`)};

  &:before {
    content: '';
    display: inline-block;
    height: ${(props) =>
      props.size !== undefined ? `${props.size}px` : `16px`};
    margin-left: ${(props) => `-${(props.size || 16) / 2}px`};
    margin-top: ${(props) => `-${(props.size || 16) / 2}px`};
    width: ${(props) =>
      props.size !== undefined ? `${props.size}px` : `16px`};

    border-color: ${(props) => `transparent ${props.color || `#65c6bd`}`};
    border-style: solid;
    border-width: 2px;

    left: ${(props) => (props.inline ? `0` : `50%`)};
    position: ${(props) => (props.inline ? `relative` : `absolute`)};
    top: ${(props) => (props.inline ? `0` : `50%`)};

    border-radius: 50%;

    animation: ${spin} 2s linear infinite;
  }
`

export const LoadingContainer = styled.div`
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: auto;
  justify-content: center;

  position: relative;
`
