// @flow

import * as React from 'react'

export default () => {
  return {
    decorators: [
      {
        strategy: (contentBlock, callback, contentState) => {
          contentBlock.findEntityRanges((character) => {
            const entityKey = character.getEntity()
            return (
              entityKey !== null &&
              contentState.getEntity(entityKey).getType() === `LINK`
            )
          }, callback)
        },
        component: (props) => {
          const prop = props
          const {contentState, entityKey} = prop

          const {url} = contentState.getEntity(entityKey).getData()

          return (
            <a
              className="link"
              href={url}
              rel="noopener noreferrer"
              target="_blank"
              aria-label={url}
              style={{cursor: `pointer`}}
              onDoubleClick={() => {
                window.open(url, `_blank`)
              }}
            >
              {prop.children}
            </a>
          )
        },
      },
    ],
  }
}
