const alignMap = {
  ALIGNCENTER: {
    display: `block`,
    textAlign: `center`,
  },
  ALIGNJUSTIFY: {
    display: `block`,
    textAlign: `justify`,
  },
  ALIGNLEFT: {
    display: `block`,
    textAlign: `left`,
  },
  ALIGNRIGHT: {
    display: `block`,
    textAlign: `right`,
  },
}

type ColorsMap = {
  [name: string]: {
    color: string,
  },
}

export const colorsMap: ColorsMap = {
  COLOR1: {
    color: `#f6d37f`,
  },
  COLOR2: {
    color: `#fac9c7`,
  },
  COLOR3: {
    color: `#83acc9`,
  },
  COLOR4: {
    color: `#ba90c2`,
  },
  COLOR5: {
    color: `#8aca7b`,
  },
  COLOR6: {
    color: `#80d0e1`,
  },
  COLOR7: {
    color: `#fbd0a5`,
  },
  COLOR8: {
    color: `#439981`,
  },
  COLOR9: {
    color: `#a8b3c6`,
  },
  COLOR10: {
    color: `#e97c9f`,
  },
  COLOR11: {
    color: `#b662a0`,
  },
  COLOR12: {
    color: `#b1c351`,
  },
  COLOR13: {
    color: `#494d56`,
  },
  COLOR14: {
    color: `#707684`,
  },
  COLOR15: {
    color: `#6473b2`,
  },
  COLOR16: {
    color: `#e78e72`,
  },
  COLOR17: {
    color: `#54b0e1`,
  },
  COLOR18: {
    color: `#65c6bd`,
  },
}

export default {
  ...alignMap,
  ...colorsMap,
}
