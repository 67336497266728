// @flow

import * as React from 'react'

import {LoadingContainer, Spinner} from './styles'

export function Loading({color, size}: {color?: string, size?: number}) {
  return (
    <LoadingContainer>
      <Spinner color={color} size={size} />
    </LoadingContainer>
  )
}
