import {EditorState, Modifier, SelectionState} from 'draft-js'
import emojiRegexRGI from 'emoji-regex/RGI_Emoji.js'
import findWithRegex from 'find-with-regex'

const emojiRegex = emojiRegexRGI()

/*
 * Attaches Immutable DraftJS Entities to the Emoji text.
 *
 * This is necessary as emojis consist of 2 characters (unicode).
 * By making them immutable the whole Emoji is removed when hitting backspace.
 */
export default function (editorState) {
  const contentState = editorState.getCurrentContent()
  const blocks = contentState.getBlockMap()
  let newContentState = contentState

  blocks.forEach((block) => {
    const text = block.getText()

    const addEntityToEmoji = (start, end) => {
      const existingEntityKey = block.getEntityAt(start)

      if (existingEntityKey) {
        // avoid manipulation in case the emoji already has an entity
        const entity = newContentState.getEntity(existingEntityKey)

        if (entity && entity.getType() === `EMOJI`) {
          return
        }
      }

      const selection = SelectionState.createEmpty(block.getKey())
        .set(`anchorOffset`, start)
        .set(`focusOffset`, end)
      const emojiText = text.substring(start, end)
      const contentStateWithEntity = newContentState.createEntity(
        `EMOJI`,
        `IMMUTABLE`,
        {emojiUnicode: emojiText}
      )
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey()

      newContentState = Modifier.replaceText(
        newContentState,
        selection,
        emojiText,
        undefined,
        entityKey
      )
    }

    findWithRegex(emojiRegex, block, addEntityToEmoji)
  })

  if (!newContentState.equals(contentState)) {
    return EditorState.push(editorState, newContentState, `change-block-data`)
  }

  return editorState
}
