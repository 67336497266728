// @flow

import 'draft-js-alignment-plugin/lib/plugin.css'
import 'draft-js-image-plugin/lib/plugin.css'
import 'draft-js-mention-plugin/lib/plugin.css'

import {Editor, EditorState, RichUtils, Modifier} from 'draft-js'
import isSoftNewlineEvent from 'draft-js/lib/isSoftNewlineEvent'
import PluginEditor from 'draft-js-plugins-editor'
import debounce from 'lodash/debounce'
import React, {lazy, Suspense} from 'react'
import {Box, Button, Flex, Text} from 'rebass'
import {createGlobalStyle} from 'styled-components'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import EditorContext from './context'
import HelperPlugins from './HelperPlugins'
import customStyleMap from './stylesMap'
import EmojiInputButton from '../EmojiInputButton'
import Toolbar from '../../plugins/toolbar/components/Toolbar'
import ChatInputsButtons from '../ChatInputsButtons'

const CharacterCounter = lazy(() =>
  import(`./components/CharacterCounter/CharacterCounter`)
)

type Props = {
  actionsAlignSelf: boolean,
  actionsFlexRowDirection: boolean,
  allowedMymeTypes: String,
  autoFocus: boolean,
  brandId: number,
  campaignId: number,
  campaignType: String,
  caption: boolean,
  chatInpuntClassName: String,
  className: string,
  editorState: EditorState,
  enableMention: boolean,
  enableUserMention: boolean,
  exceptTools: Array,
  adsInfoCounter: number,
  files: number,
  handleReturn: (editorState: EditorState) => void,
  hashtagCounters: boolean,
  hashtagIdeas: boolean,
  isChatComposer: boolean,
  loadingFile: boolean,
  mentionedUsers: Array,
  onCancelUpdload: () => void,
  onChange: (editorState: EditorState) => void,
  onHandleFile: () => void,
  onRemoveFiles: () => void,
  onSearchUserChange: () => void,
  onSendMessage: () => void,
  openPanelHashtag: (open: boolean) => void,
  openPanelHashtagIdeas: (open: boolean) => void,
  openPanelSavedReplies: (open: boolean) => void,
  openPanelAdsInfo: () => void,
  placeholder: string,
  readOnly: boolean,
  richArea: boolean,
  sendHandler: () => void,
  showAdsInfo: boolean,
  showCharacterCounter: boolean,
  showChatInputButtons: boolean,
  showEmojiPicker: boolean,
  showSavedReplies: Boolean,
  showSendButton: boolean,
}

const GlobalStyle = createGlobalStyle`
  .DraftEditor-root {
    display: flex;
    flex: 1;

    & h1,
    & h2,
    & h3,
    & ol,
    & ul {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .DraftEditor-editorContainer {
    min-width: 100%;
  }

  .hide-placeholder .public-DraftEditorPlaceholder-inner {
    display: none
  }

  .public-DraftEditorPlaceholder-root {
    color: #bbb;
    font-weight: 400;
  }

  .public-DraftEditorPlaceholder-hasFocus {
    color: #999;
  }

  .draftJsMentionPlugin__mentionSuggestions__2DWjA {
    z-index: 5!important;
  }
`

class DraftEditor extends React.Component<Props> {
  editor: Editor

  static defaultProps = {
    actionsAlignSelf: false,
    adsInfoCounter: 0,
    actionsFlexRowDirection: false,
    autoFocus: false,
    caption: false,
    enableMention: false,
    enableUserMention: false,
    exceptTools: [],
    hashtagCounters: false,
    hashtagIdeas: false,
    isChatComposer: false,
    loadingFile: false,
    mentionedUsers: [],
    placeholder: `Write something cool!`,
    readOnly: false,
    richArea: false,
    showAdsInfo: false,
    showCharacterCounter: true,
    showChatInputButtons: false,
    showEmojiPicker: true,
    showSavedReplies: false,
    showSendButton: false,
    toolbarTopPosition: false,
  }

  state = {
    isOpenSuggestion: false,
    showActions: true,
    suggestions: [],
  }

  constructor(props) {
    super(props)

    const helperPlugins = new HelperPlugins({
      isChatComposer: this.props.isChatComposer,
      showToolbar: this.props.richArea,
    })

    this.components = helperPlugins.getPluginsComponents()
    this.pluginMention = helperPlugins.getPluginMention()
    this.plugins = helperPlugins.getPlugins()
  }

  insertMention = () => {
    const {editorState} = this.props

    const currentContent = editorState.getCurrentContent(),
      currentSelection = editorState.getSelection()

    const newContent = Modifier.replaceText(
      currentContent,
      currentSelection,
      `@`
    )

    const newEditorState = EditorState.push(
      editorState,
      newContent,
      `insert-characters`
    )

    this.onChange(newEditorState)
    this.focus()
  }

  onSearchChange = debounce(async ({value}) => {
    if (!value) {
      return
    }

    const url = global.route(`api.brands.campaigns.mentions`, {
      brand_id: this.props.brandId,
      campaign_id: this.props.campaignId,
      subscription: global.env.current_account_id,
      type: `page`,
    })

    const response = await fetch(`${url}?q=${value}`)
    const json = await response.json()

    this.setState({suggestions: json.data})
  }, 300)

  onSearchUserChange = ({value}) => {
    if (!value) {
      return
    }

    this.props.onSearchUserChange(value)
  }

  onChange = (editorState: EditorState) => {
    this.props.onChange(editorState)
  }

  onFocus = () => {
    this.setState({showActions: false})
  }

  onBlur = () => {
    this.setState({showActions: true})
  }

  openPanelHashtag = (event) => {
    event.preventDefault()

    this.props.openPanelHashtag(true)
  }

  openPanelHashtagIdeas = (event) => {
    event.preventDefault()

    this.props.openPanelHashtagIdeas(true)
  }

  onCancelUpdload = () => {
    this.props.onCancelUpload()
  }

  onHandleGif = () => {
    this.props.onHandleGif()
  }

  onHandleFile = (file) => {
    this.props.onHandleFile(file)
  }

  onRemoveFiles = () => {
    this.props.onRemoveFiles()
  }

  onSendMessage = () => {
    this.props.onSendMessage()
  }

  openPanelSavedReplies = (event) => {
    event.preventDefault()

    this.props.openPanelSavedReplies(true)
  }
  openPanelAdsInfo = (event) => {
    event.preventDefault()

    this.props?.openPanelAdsInfo()
  }

  sendHandler = (event) => {
    event.preventDefault()

    this.props.sendHandler()
  }

  focus = ({fromClick} = {}) => {
    if (!this.props.readOnly && this.editor) {
      if (fromClick) {
        this.editor.focus()
      } else {
        requestAnimationFrame(() => {
          this.editor.focus()
        })
      }
    }
  }

  handleKeyCommand(command, editorState: EditorState) {
    const newEditorState = RichUtils.handleKeyCommand(editorState, command)

    if (newEditorState) {
      this.onChange(newEditorState)

      return true
    }

    return false
  }

  handleReturn = (e, editorState: EditorState) => {
    if (
      this.props.handleReturn &&
      !this.state.isOpenSuggestion &&
      !isSoftNewlineEvent(e)
    ) {
      this.props.handleReturn(e, editorState)

      return `handled`
    }

    return `not-handled`
  }

  componentDidMount(): void {
    if (this.props.autoFocus) {
      this.focus()
    }
  }

  componentDidUpdate(prevProps: Props): void {
    if (prevProps.readOnly && !this.props.readOnly) {
      this.focus()
    }
  }

  render() {
    const {
      actionsFlexRowDirection,
      actionsAlignSelf,
      caption = false,
      className = ``,
      editorState,
      exceptTools = [],
      hashtagCounters = false,
      placeholder,
      readOnly,
      richArea,
      showAdsInfo = false,
      showCharacterCounter,
      showSavedReplies = false,
      showSendButton = false,
      toolbarTopPosition = false,
      adsInfoCounter,
    } = this.props

    const {AlignmentTool} = this.components
    const {MentionSuggestions, Entry} = this.pluginMention
    const showToolbalComponents = !readOnly && richArea

    const contentBody = editorState.getCurrentContent().getPlainText(``)
    const counterHashtags = (contentBody.match(/(^|\s)#/g) || []).length

    const contentState = editorState.getCurrentContent()
    let showPlaceholder = ``
    if (!contentState.hasText()) {
      if (contentState.getBlockMap().first().getType() !== `unstyled`) {
        showPlaceholder = `hide-placeholder`
      }
    }

    return (
      <EditorContext.Provider
        value={{
          editorState,
          exceptTools: exceptTools,
          setEditorState: this.onChange,
          toolbarTopPosition: toolbarTopPosition,
        }}
      >
        <Flex
          flex={1}
          flexDirection={actionsFlexRowDirection ? `row` : `column`}
        >
          <GlobalStyle />

          {showToolbalComponents && <Toolbar />}

          <Box
            className={[className, showPlaceholder]}
            fontSize={14}
            onClick={() => this.focus({fromClick: true})}
            p={16}
            flex={1}
            css={{borderRadius: `8px`}}
          >
            <PluginEditor
              customStyleMap={customStyleMap}
              editorState={editorState}
              handleKeyCommand={this.handleKeyCommand}
              handleReturn={this.handleReturn}
              onBlur={this.onBlur}
              onChange={this.onChange}
              onFocus={this.onFocus}
              placeholder={readOnly ? undefined : placeholder}
              plugins={this.plugins}
              readOnly={readOnly}
              handlePastedFiles={(files) => {
                this.onHandleFile(files[0])
              }}
              ref={(element: any) => {
                this.editor = element
              }}
            />
            {this.props.enableMention && (
              <MentionSuggestions
                entryComponent={Entry}
                onSearchChange={this.onSearchChange}
                suggestions={this.state.suggestions}
              />
            )}

            {this.props.enableUserMention && (
              <MentionSuggestions
                entryComponent={Entry}
                onSearchChange={this.onSearchUserChange}
                suggestions={this.props.mentionedUsers}
                onOpen={() => {
                  this.setState({isOpenSuggestion: true})
                }}
                onClose={() => {
                  this.setState({isOpenSuggestion: false})
                }}
              />
            )}
          </Box>

          {showToolbalComponents && AlignmentTool && <AlignmentTool />}

          {!readOnly && !this.props.showChatInputButtons && (
            <Flex flex={actionsFlexRowDirection ? `initial` : 1}>
              <Flex
                alignItems="flex-end"
                flex={1}
                justifyContent="space-between"
                pb={actionsFlexRowDirection ? 0 : 10}
                pl={16}
                pr={this.props.showChatInputButtons ? 0 : 16}
              >
                {showCharacterCounter && (
                  <Suspense fallback={null}>
                    <CharacterCounter
                      text={editorState.getCurrentContent().getPlainText(``)}
                    />
                  </Suspense>
                )}

                {hashtagCounters && (
                  <Text
                    ml={1}
                    color={
                      counterHashtags < 31
                        ? `#c7c7c7`
                        : this.campaignType === `instaposts`
                        ? `#f15a57`
                        : `#c7c7c7`
                    }
                    fontSize={0}
                  >
                    Hashtags: {counterHashtags}
                  </Text>
                )}

                <Flex
                  ml="auto"
                  alignSelf={actionsAlignSelf ? `center` : `initial`}
                >
                  {false && (
                    <Button
                      bg="transparent"
                      color="#8899a6"
                      fontSize={12}
                      fontWeight="normal"
                      mr={2}
                      onClick={this.openPanelHashtagIdeas}
                      px={0}
                      py={0}
                      css={{
                        outline: `none`,
                        '&:hover': {
                          color: `#65c6bd`,
                        },
                      }}
                    >
                      <FontAwesomeIcon fixedWidth icon={[`fal`, `hashtag`]} />
                      <span className={`hidden-xs`}>Ideas</span>
                    </Button>
                  )}
                  {showAdsInfo && (
                    <Button
                      bg="#65c6bd"
                      color="#ffffff"
                      borderRadius="50%"
                      fontSize={10}
                      fontWeight="normal"
                      mr={2}
                      onClick={this.openPanelAdsInfo}
                      textAlign="center"
                      css={{
                        width: `18px`,
                        height: `18px`,
                        marginTop: `2px`,
                        padding: 0,
                        outline: `none`,
                      }}
                    >
                      {adsInfoCounter > 0 ? (
                        <span>{adsInfoCounter}</span>
                      ) : (
                        <FontAwesomeIcon fixedWidth icon={[`fal`, `plus`]} />
                      )}
                    </Button>
                  )}

                  {caption && (
                    <Button
                      bg="transparent"
                      color="#8899a6"
                      fontSize={12}
                      fontWeight="normal"
                      mr={2}
                      onClick={this.openPanelHashtag}
                      px={0}
                      py={0}
                      css={{
                        outline: `none`,
                        '&:hover': {
                          color: `#65c6bd`,
                        },
                      }}
                    >
                      <FontAwesomeIcon fixedWidth icon={[`fal`, `pen-alt`]} />
                      <span className={`hidden-xs`}>Captions</span>
                    </Button>
                  )}

                  {showSavedReplies && (
                    <Button
                      bg="transparent"
                      color="#8899a6"
                      fontSize={12}
                      fontWeight="normal"
                      mr={2}
                      px={0}
                      py={0}
                      title="Open Saved Replies"
                      onClick={this.openPanelSavedReplies}
                      css={{
                        outline: `none`,
                        '&:hover': {
                          color: `#65c6bd`,
                        },
                      }}
                    >
                      <FontAwesomeIcon fixedWidth icon={[`fal`, `pen-alt`]} />
                      <span className={`hidden-xs`}>Saved Replies</span>
                    </Button>
                  )}

                  {this.props.showEmojiPicker && <EmojiInputButton />}

                  {showSendButton && (
                    <Button
                      bg="transparent"
                      color="#8899a6"
                      fontSize={12}
                      fontWeight="normal"
                      title="Send"
                      ml={2}
                      px={0}
                      py={0}
                      onClick={this.sendHandler}
                      css={{
                        outline: `none`,
                        '&:hover': {
                          color: `#65c6bd`,
                        },
                      }}
                    >
                      <FontAwesomeIcon
                        fixedWidth
                        icon={[`fal`, `paper-plane`]}
                      />
                    </Button>
                  )}
                </Flex>
              </Flex>
            </Flex>
          )}

          {!readOnly && this.props.showChatInputButtons && (
            <ChatInputsButtons
              allowedMymeTypes={this.props.allowedMymeTypes}
              className={this.props.chatInpuntClassName}
              files={this.props.files}
              loadingFile={this.props.loadingFile}
              onCancelUpdload={this.onCancelUpdload}
              onHandleFile={this.onHandleFile}
              onHandleGif={this.onHandleGif}
              onHandleMention={this.insertMention}
              onRemoveFiles={this.onRemoveFiles}
              onSendMessage={this.onSendMessage}
              showActions={this.state.showActions}
            />
          )}
        </Flex>
      </EditorContext.Provider>
    )
  }
}

export default DraftEditor
