// @flow

import {library} from '@fortawesome/fontawesome-svg-core'

import {
  faAlignCenter,
  faAlignJustify,
  faAlignLeft,
  faAlignRight,
  faAt,
  faBold,
  faCloudUploadAlt,
  faCodeCommit,
  faH1,
  faH2,
  faH3,
  faHashtag,
  faHeading,
  faImage,
  faItalic,
  faLink,
  faListOl,
  faListUl,
  faMinus,
  faPalette,
  faPenAlt,
  faQuoteLeft,
  faSmile,
  faStrikethrough,
  faUnderline,
  faUpload,
} from '@fortawesome/pro-light-svg-icons'

library.add(
  faAlignCenter,
  faAlignJustify,
  faAlignLeft,
  faAlignRight,
  faAt,
  faBold,
  faCloudUploadAlt,
  faCodeCommit,
  faH1,
  faH2,
  faH3,
  faHashtag,
  faHeading,
  faImage,
  faItalic,
  faLink,
  faListOl,
  faListUl,
  faMinus,
  faPalette,
  faPenAlt,
  faQuoteLeft,
  faSmile,
  faStrikethrough,
  faUnderline,
  faUpload
)
