import * as React from 'react'
import {Manager, Popper, Reference} from 'react-popper'
import {PortalWithState} from 'react-portal'
import {Button} from 'rebass'

import {faSmile as falSmile} from '@fortawesome/pro-light-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import EmojiPicker from '../EmojiPicker'

export default function EmojiInputButton(props) {
  return (
    <PortalWithState closeOnEsc closeOnOutsideClick>
      {({closePortal, openPortal, portal}) => (
        <Manager>
          <Reference>
            {({ref}) => (
              <Button
                bg="transparent"
                className={props.className}
                color="#b3b3b3"
                fontSize="inherit"
                onClick={openPortal}
                px={0}
                py={0}
                ref={ref}
                type="button"
                css={{
                  outline: `none`,
                }}
              >
                <FontAwesomeIcon fixedWidth icon={falSmile} />
              </Button>
            )}
          </Reference>
          {portal(
            <Popper placement="auto-start">
              {({placement, ref, style}) => (
                <div
                  data-placement={placement}
                  ref={ref}
                  style={{zIndex: 2050, ...style}}
                >
                  <EmojiPicker onClick={closePortal} />
                </div>
              )}
            </Popper>
          )}
        </Manager>
      )}
    </PortalWithState>
  )
}
