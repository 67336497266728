import {getEmojiDataFromNative, Emoji} from 'emoji-mart'
import data from 'emoji-mart/data/all.json'
import React from 'react'
import styled from 'styled-components'

import {VisuallyHidden} from '@react-aria/visually-hidden'

const Span = styled.span`
  display: inline-block;
  height: 18px;
  width: 18px;

  background-position: center;
  background-repeat: no-repeat;
  caret-color: #58595b;
  vertical-align: middle;
  overflow: hidden;
`

export default function EmojiSpan(props) {
  const emojiData = getEmojiDataFromNative(props.decoratedText, `apple`, data)

  return (
    <Span data-offset-key={props.offsetKey}>
      <VisuallyHidden elementType="span" isFocusable={false}>
        {props.decoratedText}
      </VisuallyHidden>
      <span
        dangerouslySetInnerHTML={{
          __html: Emoji({
            emoji: emojiData,
            html: true,
            set: `apple`,
            size: 18,
            skin: emojiData.skin ?? undefined,
          }),
        }}
      />
    </Span>
  )
}
