// @flow

import * as React from 'react'
import {Box, Button, Flex} from 'rebass'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import EmojiInputButton from '../EmojiInputButton/index'
import styled from 'styled-components'

import gifSvg from './gif.svg'

type Props = {
  allowedMymeTypes: String,
  className?: string,
  files: Number,
  loadingFile: Boolean,
}

const InputFile = styled.input`
  position: absolute;
  height: 0;
  width: 0;
  z-index: -1;
`

class ChatInputsButtons extends React.PureComponent<Props> {
  static defaultProps = {
    files: 0,
  }

  handleFile = (event) => {
    if (event.target.files.length > 0) {
      this.props.onHandleFile(event.target.files[0])
    }
  }

  render() {
    return (
      <Flex flex={1} jus bg="#F0F0F0" justifyContent="space-between" px={10}>
        <Flex>
          <Flex>
            <Box
              as="label"
              className={this.props.className}
              color="#58595b"
              css={{cursor: `pointer`}}
              fontSize="inherit"
              type="button"
              m={0}
            >
              <InputFile
                type="file"
                accept={this.props.allowedMymeTypes}
                onChange={this.handleFile}
              />

              {this.props.loadingFile ? (
                <FontAwesomeIcon fixedWidth icon={[`fal`, `spinner`]} spin />
              ) : (
                <span className="fa-layers fa-fw">
                  <FontAwesomeIcon icon={[`fal`, `paperclip`]} />
                  {this.props.files > 0 && (
                    <span
                      style={{fontSize: `28px`}}
                      className="fa-layers-counter"
                    >
                      {this.props.files}
                    </span>
                  )}
                </span>
              )}
            </Box>

            {this.props.files > 0 && !this.props.loadingFile && (
              <Flex
                alignItems="center"
                color="#ff253a"
                css={{cursor: `pointer`}}
                fontSize="12px"
                ml={2}
                onClick={this.props.onRemoveFiles}
              >
                Remove Attachment
              </Flex>
            )}

            {this.props.loadingFile && (
              <Flex
                alignItems="center"
                color="#ff253a"
                css={{cursor: `pointer`}}
                fontSize="12px"
                ml={2}
                onClick={this.props.onCancelUpdload}
              >
                Cancel Attachment
              </Flex>
            )}
          </Flex>
        </Flex>
        <Flex>
          <EmojiInputButton className={this.props.className} color="#58595b" />

          <Button
            bg="transparent"
            className={this.props.className}
            color="#58595b"
            fontSize="inherit"
            ml={10}
            onClick={this.props.onHandleGif}
            type="button"
          >
            <img src={gifSvg} color="#58595b" alt="gif-icon" fill="#58595b" />
          </Button>

          <Button
            bg="transparent"
            className={this.props.className}
            color="#58595b"
            fontSize="inherit"
            ml={10}
            onClick={this.props.onHandleMention}
            type="button"
          >
            <FontAwesomeIcon icon={[`fal`, `at`]} />
          </Button>

          <Button
            bg="transparent"
            className={this.props.className}
            color="#58595b"
            fontSize="inherit"
            onClick={this.props.onSendMessage}
            type="button"
            ml={10}
          >
            <FontAwesomeIcon icon={[`fal`, `paper-plane`]} />
          </Button>
        </Flex>
      </Flex>
    )
  }
}

export default ChatInputsButtons
