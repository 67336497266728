// @flow

import * as React from 'react'
import {setDisplayName, wrapDisplayName} from 'recompose'

const EditorContext = React.createContext()

export const withEditorContext = (BaseComponent) => {
  const factory = React.createFactory(BaseComponent)

  class WithEditorContext extends React.Component {
    render() {
      return (
        <EditorContext.Consumer>
          {(contextProps) =>
            factory({
              ...this.props,
              ...contextProps,
            })
          }
        </EditorContext.Consumer>
      )
    }
  }

  if (process.env.NODE_ENV !== `production`) {
    return setDisplayName(wrapDisplayName(BaseComponent, `withEditorContext`))(
      WithEditorContext
    )
  }

  return WithEditorContext
}

export default EditorContext
