import {EditorState} from 'draft-js'

import emojiStrategy from './emojiStrategy'
import EmojiSpan from './components/EmojiSpan'
import attachImmutableEntitiesToEmojis from './modifiers/attachImmutableEntitiesToEmojis'

export default () => {
  return {
    decorators: [
      {
        component: EmojiSpan,
        strategy: emojiStrategy,
      },
    ],

    onChange: (editorState) => {
      let newEditorState = attachImmutableEntitiesToEmojis(editorState)

      if (
        !newEditorState
          .getCurrentContent()
          .equals(editorState.getCurrentContent())
      ) {
        // Forcing the current selection ensures that it will be at it's right place.
        // This solves the issue where inserting an Emoji on OSX with Apple's Emoji
        // selector led to the right selection the data, but wrong position in
        // the contenteditable.
        newEditorState = EditorState.forceSelection(
          newEditorState,
          newEditorState.getSelection()
        )
      }

      return newEditorState
    },
  }
}
