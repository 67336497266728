// @flow

import * as React from 'react'
import Loadable from 'react-loadable'
import styled from 'styled-components'

import {Loading} from '../loading'

const LoadingContainer = styled.div`
  display: flex;
  height: 358px;
  width: 338px;

  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 1px 10px rgba(0, 0, 0, 0.3);
`

/* prettier-ignore */
export default Loadable({
  loader: () => import(`./container` /* webpackChunkName: "emoji-picker" */),
  loading: () => <LoadingContainer><Loading /></LoadingContainer>
});
